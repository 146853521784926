.students-top{
    display: flex;
    justify-content: right;
}
.students-top button{
    font-family: "Inter";
    font-size: 11px;
    font-size: 600;
    color: white;
    background-color: #263238;
    padding: 12px 50px;
    border-radius: 5px;
    border: none;
}
.students-body{
    margin-top: 14px;
    background-color: #E5ECF6;
    height: 500px;
  
    padding: 24px 48px;
    border-radius: 5px;
}
.students-table-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.student-table-body{
    height:400px;
    overflow-y: scroll;
    margin-top: 10px;
}
.students-info h4{
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
    color: #298097;
}
.students-info p{
    font-family: "Poppins";
    font-size: 13px;
    font-weight: 500;
    color: #16C098;
}
.students-filter{
    width: 50%;
    display: flex;
    align-items: right;
    /* gap: 21px; */
}
.students-filter:first-child{
    width: 65%;
}
.students-select{
    width: 40% !important;
}
.students-search{
    width: 100%;
    padding: 10px 12px;
    background-color: #F9FBFF;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
}
.students-search svg{
    font-size: 16px;
    font-weight: 500;
    color: #7E7E7E;
}
.students-search input{
    width: 100%;
    background-color: transparent;
    border: none;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    color: #B5B7C0;
}
.students-search input::placeholder{
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    color: #B5B7C0;
}
.students-search p{
    width: 50%;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    color: #7E7E7E;
}
.students-search select{
    /* width: 78%; */
    border: none;
    background-color: transparent;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 800;
    color: #3D3C42;
}
.charges-note{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-family: "Poppins";
    font-size: 16px;
    color: #3D3C42;
}
@media (max-width: 800px){
    .students-table-top div{
        
    }
    .students-info{
        width: 48%;
    }
    .students-info h4{
        font-family: "Poppins";
        font-size: 14px;
        font-weight: 700;
        color: #298097;
    }
    .students-info p{
        font-family: "Poppins";
        font-size: 12px;
        font-weight: 500;
        color: #16C098;
    }
    .students-filter{
        width: 48%;;
        display: flex;
        align-items: center;
        gap: 11px;
    }
    .students-body{
        margin-top: 14px;
        background-color: #E5ECF6;
        height: 500px;
        padding: 1rem;
        border-radius: 5px;
    }
    .students-select{
        width: 40% !important;
    }
    .students-search{
        width: 100%;
        padding: 0.5rem;
    }
    .students-search svg{
        font-size: 12px;
        font-weight: 500;
        color: #7E7E7E;
    }
    .students-search input{
        font-size: 10px;
    }
    .students-search input::placeholder{
        font-size: 10px;
    }
    .students-search p{
        font-size: 8px;
    }
    .students-search select{
        font-size: 8px;
    }
}