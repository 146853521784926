.home-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.preloader{
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home-top p{
    font-size: 14px;
    font-weight: 500;
    color: black;
}
.home-top select{
    background-color: #058B42;
    border-radius: 8px;
    padding: 0.5rem;
    color: white;
    font-weight: 500;
}
.home-body{
    margin-top: 18px;
}
.home-card{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 28px;
}
.card-div{
    width: 50%;
}
.home-tables{
    padding: 1.5rem;
    height: 400px;
    overflow-y: scroll;
    margin-top: 24px;
    background-color: #E5ECF6;
    border-radius: 16px;
}
.home-tables p{
    margin-left: 14px;
}
.empty-animate{
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.button-con{
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
}
.create-button{
    color: #ffffff;
    padding: 22px 95px;
    background-color: #263238;
    border-radius: 8px;
    font-family: "Inter";
    font-size:20px;
    font-weight: 600;
}
@media (max-width:800px){
    .home-card{
        /* overflow-x: scroll; */
    }
    .card-div{
        width: 70% !important;
    }
    .create-button{
        color: #ffffff;
        padding: 1.5rem;
        width: 100%;
        background-color: #263238;
        border-radius: 8px;
        font-family: "Inter";
        font-size:16px;
        font-weight: 600;
    }
}