.invoice-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #A3A0A0;
}
.invoice-top h4{
    font-size: 24px;
    font-weight: 600;
    color: black;
}
.student-invoice{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}
.invoice-list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 25%;
}
.invoice-list p{
    text-align: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: black;
    padding-bottom: 10px;
}
.active-p{
    border-bottom: 3px solid #FCBA2D;
}
.invoice-body{
    margin-top: 44px;
    display: flex;
    align-items: normal;
    gap: 36px;
}
.mastercard{
    width: 8%;
}
.invoice-payment{
    width: 60%;
}
.card-info{
    margin-top: 16px;
    width: 100%;
}
.invoice-period{
    width: 40%;
}
.form-head{
    font-size: 24px;
    color: #298097;
    font-weight: 600;
}
.payment-form{
    margin-top: 14px;
    padding:24px 29px;
    border: 1px solid #C4C4C4;
    border-radius: 14px;
}
.form-1{
    width: 100%;
}
.form-1 label{
    font-size: 14px;
    color: #919499;
    font-weight: 500;
}
.form-1 label span{
    color: #D70000;
    margin-left: 4px;
}
/* .input-search-name{
    width: 100%;
    margin-top: 14px;
    margin-bottom: 15px;
    padding: 20px 21px;
    border-radius: 14px;
    border: 1px solid #C4C4C4;
    display: flex;
    justify-content: space-between;
} */
.input-search-name input{
    width: 100%;
    margin-top: 14px;
    margin-bottom: 15px;
    padding: 20px 21px;
    border-radius: 14px;
    border: 1px solid #C4C4C4;
    background-color: transparent;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    color: #474747;
}
.input-search-name input:focus{
    background-color: #F5F7FB;
    border: 1px solid #C4C4C4;
}
.input-search-name textarea{
    width: 100%;
    height: 120px;
    background-color:  transparent;
    border: none;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    color: #474747;
}
.input-search-name svg{
    color: #8A8A8A;
}
.form-2{
    display: flex;
    align-items: last baseline;
    gap: 22px;
}
.form-2 div{
    width: 100%;
}
.select-field{
    width: 100%;
    margin-top: 14px;
    margin-bottom: 15px;
}
.select-field select{
    width: 100%;
    padding: 20px 21px;
    border-radius: 14px;
    border: 1px solid #C4C4C4;
    font-size: 14px;
    font-family: "Inter";
    font-weight: 400;
    color: #474747;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../Assets/Image/arrow.png');
    background-size: 10px;
    background-position: 96% center;
    background-repeat: no-repeat;
}
.save-con{
    margin-top: 20px;
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: center;
}

.save-con button{
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background-color: #298097;
    padding: 8px;
    border-radius: 14px;
    border: none;
}
/* .save-con-4{
    margin-top: 20px;
    width: 70%;
    display: flex;
    gap: 15px;
    justify-content: center;
} */

.save-con-4 button{
    width: 60%;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background-color: #298097;
    padding: 8px;
    border-radius: 14px;
    border: none;
}
.save-con-2 button{
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background-color: #298097;
    padding: 8px;
    border-radius: 14px;
    border: none;
}
.share{
    background-color: white !important;
    color: #298097 !important;
    border: 1px solid #298097 !important;
}
.student-invoice-top{
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.student-invoice-top h4{
    font-size: 24px;
    font-family: "Poppins";
    font-weight: 700;
    color: #298097;
}
.student-invoice-top button{
    font-size: 20px;
    font-family: "Poppins";
    padding: 7px 22px;
    font-weight: 700;
    color: #298097;
    background-color: transparent;
    border: 1px solid #298097;
    border-radius: 14px;
}
.invoice-progressbar{
    margin-top: 28px;
    width: 100%;
    padding: 8px 26px;
    border-radius:15px;
    border:1px solid #C4C4C4;
}
.ngn-progressbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ngn-progressbar p{
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 800;
    color: #298097;
}
.invoice-form .input-search-name input{
    background-color: #F5F7FB;
    border: 1.29px solid #C4C4C4;
}
.invoice-form .input-search-name input{
    background-color: #F5F7FB;
    border: 1.29px solid #C4C4C4;
}
.progress-bar{
    width: 100%;
    height: 35px;
    border-radius: 8px;
    margin-top: 13px;
    background-color: #F5F7FB;
}
.progress-bar-fill{
    height: 100%;
    border-radius: 8px;
    background-color: #E3F5FF;
    transition: width 0.5s ease-out;
}
.progress-label{
    text-align: right;
    margin-top: 12px;
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 600;
    color: #298097;
    transition: width 0.5s ease-out;
}
@media (max-width: 800px){
    .student-invoice-top h4{
        font-size: 18px;
    }
    .student-invoice-top button{
        font-size: 14px;
        font-family: "Poppins";
        padding: 7px 10px;
        font-weight: 600;
        color: #298097;
        background-color: transparent;
        border: 1px solid #298097;
        border-radius: 14px;
    }
    .invoice-body{
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        align-items: normal;
        gap: 36px;
    }
    .invoice-payment{
        width: 100%;
    }
    .invoice-period{
        width: 100%;
    }
    .form-2-mobile{
        flex-direction: column !important;
    }
}