@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* .css-11xur9t-MuiPaper-root-MuiTableContainer-root{
  background-color: transparent !important;
  box-shadow: none !important;
} */
input:focus{
  outline: none;
  /* background-color: #F5F7FB;
  border: 1.29px solid #C4C4C4; */
}
textarea:focus{
  background-color: #F5F7FB;
  border: 1.29px solid #C4C4C4;
}
select:focus{
  outline: none;
  /* background-color: #F5F7FB;
  border: 1.29px solid #C4C4C4; */
}
a{
  text-decoration: none;
  color: black;
}
.spinner{
  width: 20%;
  display: flex;
  align-items: center;
}
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.614);
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}