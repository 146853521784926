.modal-background{
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
}
.alert-box{
    position: absolute;
    top: -60px;
    /* top: 1rem;
    right: 1rem ; */
}
.modalClose{
    position: absolute;
    right: 0;
    top: -40px;
    background-color: white;
    height: 25px;
    width: 25px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.459);
    border-radius: 50%;
}
.modalss{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    position: relative;
    padding: 30px;
    /* padding-bottom: 103px; */
    width: 30% !important;
    background-color: white;
    border-radius: 8px;
}
.receipt-modal{
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: relative;
    padding: 1rem;
    /* padding-bottom: 103px; */
    height: 700px;
    overflow-y: scroll;
    width: 50% !important;
    background-color: white;
    border-radius: 8px;
}
.modalssss{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 5;
    position: relative;
    padding: 30px;
    /* padding-bottom: 103px; */
    width: 50% !important;
    background-color: white;
    border-radius: 8px;
}
.onetime-modal{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.onetime-modal form, .modalssss form{
    width: 100%;
}
.modal-form{
    width: 100%;

}
.loadinganimation, .animation{
    width: 100%;
    display: flex;
    align-items: center;
}
.create-payment{
    width: 100%;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5rem;
    color: #2B2B2B;
}
.modal-inner{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}
.modal-left, .modal-right{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.modal-left svg, .modal-right svg{
    font-size: 3rem;
    color: #B11226B2;
}
.modal-left-icon, .modal-left a, .modal-right a{
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}
.payment-method{
    margin-top: 8px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    color: #2B2B2B;
}
.payment-details{
    margin-top: 8px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #2B2B2B;
}

.payment-choose{
    margin-top: 16px;
    padding: 0.4375rem 1.9375rem;
    background: #F2F2F2;
    border: none;
    border-radius: 2.31351px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 17px;
    color: #555555;
}
.cardpin-body{
    margin-top: 100px;
    display: flex;
    width: 100%;
    justify-content: center;
}
.cardpin-body-inner{
    width: 100%;
}
.enter-pin{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #000000;
}
.field-container{
    margin-top: 20px;
    padding-bottom: 100px;
    display: flex;
    justify-content: space-between;
}
.pinfield input{
    width: 80px;
    height: 80px;
    font-size: 32px;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    border: none;
    margin: 2px;
    background-color: #D6DEE0;
    color: #000000;
    outline: none;
    transition: all 0.1s;
}
.pinfield input:focus{
    background-color: #F1C37E;
}
.receipt-top{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.credio{
    font-size: 26px;
    font-family: "Poppins";
    font-weight: 800;
    color: #B11226;
}
.receipt-modal h3{
    font-size: 29px;
    font-family: "Poppins";
    font-weight: 800;
    color: #333333;
}
.success-text{
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #058B42;
}
.receipt-date-time{
    font-size: 13px;
    font-family: "Poppins";
    font-weight: 500;
    color: #33333381;
}
.receipt-body{
    margin-top: 20px;
}
.receipt-con{
    padding-top: 12px;
    padding-bottom: 20px;
    border-top: 1px dashed #DCDEE0;
}
.con-head{
    font-size: 19px;
    font-family: "Poppins";
    font-weight: 600;
    color: #333333;
}
.receipt-content{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.receipt-content p{
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 500;
    color: #33333381;
}
@media (Max-width: 500px){
    .cardpin-body{
        margin-top: 30px;
    }
    .cardpin-body-inner{
        width: 90%;
    }
    .enter-pin{
        text-align: center;
        font-weight: 700;
        font-size: 17px;  
    }
}
@media (max-width: 1356px){
    .modal-left, .modal-right{
        width: 43%;
    }
}
@media (max-width: 1280px){
    .modal{
        width: 55%;
    }
    .modal-left, .modal-right{
        width: 43%;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 1024px){
    .modal{
        width: 58%;
    }
    .modal-left, .modal-right{
        width: 43%;
        display: flex;
        flex-direction: column;
    }
}
@media (max-width: 1000px){
    .modal{
        width: 80%;
        padding: 35px;
        padding-bottom: 35px;
    }
    .modal-inner{
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
    }
    .create-payment{
        font-size: 0.75rem;
    }
    .modal-left svg, .modal-right svg{
        font-size: 2rem;
    }
    .payment-method{
        font-size: 0.635625rem;
        color: #2B2B2B;
    }
    .payment-details{
        font-size: 0.4375rem;
    }
    .payment-choose{
        font-size: 0.5875rem;

    }
    .pinfield input{
        width: 30px;
        height: 30px;
        font-size: 16px;
        padding: 5px;
    }
}