.sidebar{
    position: fixed;
    width: 220px;
    height: 100vh;
    background-color: white;
    border-right: 1px solid rgba(0, 0, 0, 0.18);
    padding: 1.5rem 1.5rem;
}
.sidebar-logo{
    display: flex;
    align-items: center;
    gap: 8px;
}
.sidebar-logo img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.sidebar-close{
    display: none;
}
.logo-text{
    font-family: "Inter";
    font-size: 14px;
    color: black;
    font-weight: 400;
}
.sidebar-nav{
    margin-top: 36px;
}
.sidebar-nav ul{
    list-style: none;
}
.sidebar-nav ul li{
    margin-top: 24px;
    padding: 0.5rem;
}
.active{
    background-color: #E3F5FF;
    border-radius: 12px;
}
.logout{
    position: absolute;
    bottom: 2.5rem;
    display: flex;
    align-items: center;
    gap: 8px;
}
@media (max-width: 800px){
    .sidebar{
        transform: translateY(-110%);
        transition: all 0.4s;
        z-index: 100;
    }
    .sidebaropen{
        transform: translateX(0%);
    }
    .sidebar-top-logo{
        display: flex;
        align-items: center;
        justify-content: space-between;
    } 
    .sidebar-close{
        display: block;
    }
}