.login{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.circle-1{
    position: absolute;
    top: -300px;
    right: -200px;
    width: 763px;
    height: 763px;
    border-radius: 50%;
    background-color: #E5ECF6;
    z-index: 3;
}
.circle-2{
    position: absolute;
    bottom: -300px;
    left: -200px;
    width: 763px;
    height: 763px;
    border-radius: 50%;
    background-color: #298097;
    z-index: 3;
}
.login-inner{
    width: 70%;
    height: 600px;
    display: flex;
    border-radius: 30px;
    background-color: white;
    z-index: 4;
    box-shadow: 0px 9.83px 9.83px rgba(0, 0, 0, 0.132);
}
.signup-inner{
    height: 690px;
}
.login-left{
    background-image:  url(../../Assets/Image/background.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 50%;
    height: 100%;
    border-radius: 30px 0px 0px 30px;
    
}
.login-right{
    width: 50%;
    height: 100%;
    padding: 0px 102px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.signup-right{
    padding: 0px 70px;
}
.login-left-bottom{
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: end;
    height: 334px;
    border-radius: 0px 0px 0px 30px;
    background: linear-gradient(to bottom, #0000000a 0%, #000000c1 100%);
    /* background-color: #989898; */
}
.login-left h4{
    text-align: center;
    /* position: absolute;
    bottom: 63px; */
    color: white;
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 42px;
}
.login-right h4{
    font-family: "Poppins";
    font-size: 24px;
    font-weight: 700;
}
.login-right p{
    margin-top: 8px;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 500;
    color: #989898;
}
.error-box{
    width: 100%;
    padding: 1rem;
    background-color: rgb(233, 144, 144);
    border-radius: 8px;
}
.error-box p {
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 600;
    color: #101010;
}
.login-form{
    margin-top: 18px;
    width: 100%;
}
.form-3 label{
    margin-bottom: 9px;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 500;
}
.form-3 input, .form-3 select{
    width: 100%;
    background-color: transparent;
    border: 1px solid #C4C4C4;
    border-radius: 11px;
    padding: 14px 12px;
    font-size: 14px;
    font-family: "Poppins";
    font-size: 500;
    color: #1e1d1d;
    margin-bottom: 19px;
}
.password-input{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #C4C4C4;
    padding: 14px 12px;
    border-radius: 11px;
}
.password-input input{
    border: none;
    padding: 0px;
    margin-bottom: 0px;
}
.password-input input:focus{
    outline: none !important;
    background-color: transparent !important;
    border: none !important;
}
.psw-visible svg{
    color: #C4C4C4;
}

.form-3 select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../Assets/Image/arrow.png');
    background-size: 10px;
    background-position: 92% center;
    background-repeat: no-repeat;
}
.form-3 input::placeholder{
    font-size: 14px;
    font-family: "Poppins";
    font-size: 500;
    color: #C4C4C4;
}
.form-3 input:focus{
    background-color: #F5F7FB;
    border: 1px solid #C4C4C4;
}
.forget{
    color: #298097;
    font-size: 15px;
    font-family: "Poppins";
    font-size: 600;
}
.start-button{
    margin-top: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 17px;
    background-color: #298097;
    font-family: "Poppins";
    font-size: 500;
    border-radius: 17px;
    border: none;
    color: #ffffff;
    padding: 7px;
}
.already{
    margin-top: 13px;
    text-align: center;
    color: #989898;
    font-size: 13px;
    font-family: "Poppins";
    font-size: 500;
}
.already button{
    margin-left: 5px;
    background-color: transparent;
    border: none;
    color: #298097;
    font-size: 14px;
    font-family: "Poppins";
    font-size: 800;
}
.already span{
    color: #298097;
    font-size: 16px;
    font-family: "Poppins";
    font-size: 800;
}
@media (max-width: 800px){
    .login-inner{
        width: 90%;
        height: 70%;
        /* display: flex;
        border-radius: 30px;
        background-color: white;
        z-index: 4;
        box-shadow: 0px 9.83px 9.83px rgba(0, 0, 0, 0.132); */
    }
    .signup-inner{
        height: 90% !important;
    }
    .login-left{
        display: none;
    }
    .login-right{
        width: 100%;
        padding: 0px 18px;
    }
    .login-form{
        margin-top: 40px;
        width: 100%;
    }
    .start-button{
        margin-top: 40px;
    }
}